@use "@material/typography"

.app-spec-card
  &__body
    padding: 8px 16px 16px 16px
    @include typography.typography(body2)

  &__title
    padding: 16px 16px 8px 16px
    @include typography.typography(subtitle1)
