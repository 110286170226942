.page
  display: flex
  flex-flow: column nowrap
  height: 100%
  &__header
    flex: 0 0 auto
    display: flex
    flex-flow: row nowrap
  &__wrapper
    flex: 1 0 auto
    display: flex
    flex-flow: column nowrap
