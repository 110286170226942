@use "@material/typography"

.app-spec-form
  display: flex
  flex-flow: column nowrap

  &__error-message
    color: red

  &__field
    display: flex
    flex-flow: column nowrap
    max-width: 480px

    &__label
      @include typography.typography(subtitle1)

  &__list
    display: flex
    flex-flow: column nowrap

    &__item
      display: flex
      flex-flow: column nowrap
