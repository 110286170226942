@use "@material/theme"

.sign-in
  margin: auto
  display: flex
  width: 100%
  height: 100%

.sign-in__info-block
  display: flex
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%
  flex-direction: column
  align-items: center
  justify-content: center

.sign-in__button
  display: flex

.sign-in__background-animation
  display: flex
  max-width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%
  align-items: center
  justify-content: center

.sign-in__text
  display: flex
  text-align: center
  max-width: 400px
  @include theme.property(color, on-background)
  @media (max-width: 576px)
    max-width: 200px
