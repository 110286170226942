.loading
  margin: auto
  display: flex
  flex-flow: column nowrap
  align-items: center
  width: 900px
  max-width: 100%

  &__hint
    font-style: italic
