$color-scheme: light !default
@use "normalize.css/normalize.css"
@use "material-theme" with ( $color-scheme: $color-scheme )
@use "node_modules/@material/theme" with ($background: material-theme.color(background), $error: material-theme.color(error), $primary: material-theme.color(primary), $on-error: material-theme.color(on-error), $on-primary: material-theme.color(on-primary), $on-secondary: material-theme.color(on-secondary), $on-surface: material-theme.color(on-surface), $secondary: material-theme.color(secondary), $surface: material-theme.color(surface))
@use "node_modules/@material/typography" with ($styles-headline1: material-theme.style(display2), $styles-headline2: material-theme.style(display3), $styles-headline3: material-theme.style(headline1), $styles-headline4: material-theme.style(headline2), $styles-headline5: material-theme.style(headline3), $styles-headline6: material-theme.style(headline4), $styles-subtitle1: material-theme.style(subhead1), $styles-subtitle2: material-theme.style(subhead2), $styles-body1: material-theme.style(caption), $styles-body2: material-theme.style(body2), $styles-caption: material-theme.style(caption), $styles-button: material-theme.style(button), $styles-overline: material-theme.style(overline))
@use "@material/button/index.scss" as button
@use "@material/drawer/index.scss" as drawer
@use "@material/top-app-bar/index.scss" as top-app-bar
@use "material-components-web/material-components-web"

@use "app"
@use "app-card"
@use "app-spec-card"
@use "app-spec-detail"
@use "app-spec-form"
@use "card-grid"
@use "dashboard"
@use "header"
@use "loading"
@use "lottie"
@use "not-found"
@use "page"
@use "sign-in"

.mdc-top-app-bar
  @include top-app-bar.fill-color(material-theme.color(background))
  @include top-app-bar.icon-ink-color(material-theme.color(on-surface))
  @include top-app-bar.ink-color(material-theme.color(on-surface))
  width: calc(100% - 256px)
  height: 64px
  border-bottom: 1px solid material-theme.color(outline)

  .mdc-button
    @include button.container-fill-color(material-theme.color(background))
    @include button.icon-color(material-theme.color(on-surface))
    @include button.ink-color(material-theme.color(on-surface))

  .mdc-icon-button
    padding: 0

    img
      width: 42px
      height: 42px

  &__section
    &#{&}--align-end
      margin-left: -1px
      margin-right: -10px

      .mdc-button
        margin: 0 1px

      .mdc-icon-button
        margin: 0 10px

.mdc-drawer
  @include drawer.surface-fill-color(material-theme.color(surface-variant))
  @include drawer.border-color(material-theme.color(outline))

  &__header
    padding: 8px 16px
    height: 64px
    border-bottom: 1px solid material-theme.color(outline)

  &__title
    display: flex
    flex-flow: row nowrap
    align-items: center
    font-weight: 500

    img
      margin-right: 12px
      width: 42px
      height: 42px

  .mdc-deprecated-list-group__subheader
    text-transform: uppercase
    font-weight: 500

body,
html
  height: 100%
  @include theme.property(background-color, background)

body
  @include typography.base()
