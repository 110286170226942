.dashboard
  display: flex
  flex-flow: row nowrap
  width: 100%
  height: 100%

  &:before
    content: " "
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    cursor: wait
    display: none
    background-color: #fff
    z-index: 10000
    opacity: 0
    will-change: opacity
    transition: opacity linear 300ms

  .app--loading &:before
    display: block
    opacity: 0.55

  &__wrapper
    display: flex
    flex-flow: row nowrap
    flex: 1 1 auto

  &__content-outer
    display: flex
    flex-flow: column nowrap
    align-items: stretch
    flex: 1 1 100%
    overflow-y: auto

  &__content-inner
    padding: 24px
    max-width: 1200px
    flex: 1 1 auto
    margin: 0 auto
    width: 100%
    box-sizing: border-box

  .mdc-drawer__content .mdc-deprecated-list-group__subheader
    padding-top: 16px
