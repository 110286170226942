@use "@material/typography"

.app-card
  position: relative
  border-radius: var(--mdc-theme-surface, 4px)

  &__loading-scrim
    position: absolute
    background-color: rgba(0, 0, 0, 0.1)
    top: 0
    left: 0
    bottom: 0
    right: 0
    z-index: 1
    display: none
    flex-flow: column nowrap
    justify-content: center
    align-items: center

    .mdc-circular-progress
      margin: 10px

  &--loading &__loading-scrim
      display: flex

  &--loading .mdc-card
      opacity: 0.1

  &__body
    padding: 8px 16px 16px 16px
    @include typography.typography(body2)

  &__title
    padding: 16px 16px 8px 16px
    @include typography.typography(subtitle1)
