@use "sass:math"

.lottie
  content: " "
  display: flex
  height: 0
  padding-top: math.div(10, 16) * 100%
  width: 100%
  background: transparent
  background-position: center
  background-size: cover
  position: relative
  speed: 1
