.card-grid
  display: grid
  gap: 10px

  grid-template-columns: 1fr
  @media (min-width: 576px)
    grid-template-columns: repeat(1, 1fr)
  @media (min-width: 768px)
    grid-template-columns: repeat(2, 1fr)
  @media (min-width: 992px)
    grid-template-columns: repeat(3, 1fr)
