//
// Copyright 2020 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

// Selector '.mdc-*' should only be used in this project.
// stylelint-disable selector-class-pattern

@use '@material/feature-targeting/feature-targeting';
@use '@material/ripple/ripple';
@use '@material/ripple/ripple-theme';
@use './segment-theme';

$ripple-target: '.mdc-segmented-button__ripple';

@mixin ripple($query: feature-targeting.all()) {
  $feat-structure: feature-targeting.create-target($query, structure);

  .mdc-segmented-button__segment {
    @include ripple.surface($query: $query, $ripple-target: $ripple-target);
    @include ripple.radius-bounded(
      $query: $query,
      $ripple-target: $ripple-target
    );
    @include ripple-theme.states(
      segment-theme.$selected-ink-color,
      true,
      $query: $query,
      $ripple-target: $ripple-target
    );
    @include feature-targeting.targets($feat-structure) {
      overflow: hidden;
    }

    #{$ripple-target} {
      @include ripple.target-common($query: $query);
    }
  }
}
