@use "sass:math"

.header
  font-family: "Source Sans Pro", sans-serif
  display: inline-flex
  font-size: 36px
  align-self: center
  height: 60px
  align-items: center
  &__fysi
    font-weight: 300
  &__web
    font-weight: 200
